import { registerEnvCommon } from '@bcflit-env/env-token';
import { env, envCommon } from '@bcflit-env/janusz-casino/env.prod-no-proxy';
import '@bcflit-polyfills';
import { releaseVersion } from '@bcflit-release-version';
import { bootstrapAppI18n } from '@tmf-janusz-casino-mobile-app/app-i18n';
import { boostrapApplication } from '@tmf-janusz-casino-mobile-twa-app/app';
import { initGcpErrorReporting } from '@tmf-shared-platform/gcp-error-reporting/init-gcp-error-reporting';
import { appInitRedirector } from '@tmf-shared-utils/app-init-redirector';
import { litswAppInitializer } from '@tmf-ui-shared/litsw/litsw-app-initializer';
import { setReleaseVersion } from '@tmf-ui-shared/misc/set-release-version';

async function main(): Promise<void> {
  registerEnvCommon(envCommon);
  appInitRedirector();
  setReleaseVersion(releaseVersion, document);
  initGcpErrorReporting('jz-mobile-twa', releaseVersion);
  litswAppInitializer('/mobile-sw.js', { enabled: true });
  await bootstrapAppI18n(env);
  boostrapApplication(env);
}
main();
