import { EnvTma } from '@bcflit-env/types';

export const envCommon: Partial<EnvTma> = {
  baseDomainName: 'januszcasino',
  affiliateIdQueryParamName: 'btag',
  paginationLimit: 10,
  jurisdiction: 'CW',
  langs: ['pl', 'en', 'hu', 'de'],
  allGamesCollection: 'jz_all-games',
  defaultJurisdiction: 'CW',
  iovationLibUrl:
    'https://binema-ro-stg-wl.stg.themill.tech/iojs/general5/PmDbidjGyHdWLOlel541XENqtTCYSLt0tZxs-EE-LQo/static_wdp.js?loaderVer=5.1.0&compat=false&tp=true&tp_split=false&fp_static=true&fp_dyn=true&flash=false',
  playTogetherAmounts: [25, 50, 100, 200],
  ladeskChatConfig: {
    perLangConfig: {
      en: {
        chatId: 'gskol1l9',
        chatIdForLoggedInUser: 'aletxr4c'
      },
      pl: {
        chatId: 'gk8fnlo9',
        chatIdForLoggedInUser: '3xzbw8z3'
      },
      de: {
        chatId: '9haxa3i0',
        chatIdForLoggedInUser: 'z2oy20z5'
      },
      hu: {
        chatId: 'v16izr12',
        chatIdForLoggedInUser: 'ul8lqf71'
      }
    },
    scriptUrl: 'https://supportpage.ladesk.com/scripts/track.js'
  },
  webpConfig: {
    brand: 'januszcasino',
    hostUrl: 'https://image-assets-server-dot-cms-headless-gae.ew.r.appspot.com/'
  },
  domainSettingsUrl: 'https://the-mill-janusz-casino.ew.r.appspot.com/_domain-settings'
};
