import { EnvCommon, EnvTma } from '@bcflit-env/types';
import { envBaseCommon } from './env.base';
import { envCommon as envCommonOld } from './env.common';

export const env: EnvTma = {
  ...(envCommonOld as EnvTma),
  brandId: 'jz',
  graphqlApiUrl: 'https://api-tmasw1-prd.tmasw.tech/gql',
  graphqlWsUrl: 'wss://websocket-tmasw1-prd.tmasw.tech',
  gameAssetsBaseUrl: 'https://game-assets-tmasw1-prd.tmasw.tech/',
  cmsApiUrl: 'https://cms-janusz-dot-the-mill-janusz-casino.ew.r.appspot.com/',
  allGamesCollection: 'jz_all-games'
};

export const envCommon: EnvCommon = {
  ...envBaseCommon
};
